import React from "react";

const TreeImage = ({ userInformation, avatarView }) => {
  const treeLevel = () => {
    switch (true) {
      case userInformation.level <= 0:
        return 1;
      case userInformation.level >= 10:
        return 10;
      default:
        return userInformation.level;
    }
  };

  return (
    <img
      style={avatarView && { maxHeight: "200px" }}
      alt="Progress Tree"
      src={`../tree/treeKey${userInformation.treeIndex}/tree${treeLevel()}.svg`}
    />
  );
};

export default TreeImage;

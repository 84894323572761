import React, { useState, useEffect, useContext } from "react";
import styles from "./css/dailyChallenge.module.scss";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import { StoreContext } from "../Context";
import { logButton } from "../analytics";

const useWindowSize = () => {
  const [size, setSize] = useState([window.outerWidth, window.outerHeight]);

  useEffect(() => {
    const handleResize = () => {
      setSize([window.outerWidth, window.outerHeight]);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return size;
};

function DailyChallenge() {
  //Toggle popUp Window for extra confirm completed Task
  const [toggle, setToggle] = useState(false);
  const [selectedTask, setSelectedTask] = useState(false);
  const {
    state: { userData },
    actions,
  } = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [windowWidth] = useWindowSize();
  const [mobileVersion, setMobileVersion] = useState(false);

  useEffect(() => {
    if (userData.dailychallenge) {
      return;
    }
    const fetchDailyChallenge = async () => {
      try {
        const dailyChallenge = (await axios.get(`${process.env.REACT_APP_API_URL}/v1/challenge`)).data;
        actions.updateContext({
          ...userData,
          dailychallenge: dailyChallenge,
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchDailyChallenge();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    windowWidth < 960 ? setMobileVersion(true) : setMobileVersion(false);
  }, [windowWidth]);

  const completeAndPush = async (challenge) => {
    setLoading(true);
    await axios.post(`${process.env.REACT_APP_API_URL}/v1/player/challenge`, challenge).then(
      (response) => {
        //Add pinecones on pinecones instead of challenge.experience, we havent added pinecons to backend yet.
        if (response.status === 201) {
          actions.updateContext({
            ...userData,
            level: response.data.player.level,
            experience: response.data.player.experience,
            pinecones: response.data.player.pinecones,
            challengeActivities: [...userData.challengeActivities, { code: challenge.code, created: new Date() }],
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
    setLoading(false);
    setToggle(false);
  };

  const completeChallengePopUp = () => {
    return (
      <div
        className={styles.backgroundOverLay}
        onClick={(e) => {
          if (e.currentTarget === e.target) {
            e.stopPropagation();
            setToggle(false);
          }
        }}
      >
        <div className={styles.completeChallengeWrapper}>
          <div className={styles.completeTitle}>I have completed</div>
          <div className={styles.completeChallengeName}>{selectedTask.description}</div>
          <div className={styles.completeButtonWrapper}>
            <button
              data-analyticsidentifier={"close_" + selectedTask.code}
              className={styles.closeButton}
              onClick={(e) => {
                setToggle(false);
                logButton(e);
              }}
            >
              X
            </button>
            <button
              data-analyticsidentifier={"complete_" + selectedTask.code}
              className={styles.submitButton}
              onClick={(e) => {
                completeAndPush(selectedTask);
                logButton(e);
              }}
            >
              {!loading ? (
                "Complete challenge"
              ) : (
                <img alt={`Loading purchase`} src={"../Icons/loading.svg"} className={styles.loading}></img>
              )}
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>Daily challenges</h1>
        {toggle && completeChallengePopUp()}
        <div className={styles.grid} style={toggle ? { pointerEvents: "none" } : {}}>
          {userData.dailychallenge &&
            userData.dailychallenge.map((challenge, index) => {
              return (
                <div
                  key={challenge.code + index}
                  style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                >
                  <div data-tip={challenge.elaboration} className={styles.challengeWrapper}>
                    <img alt={challenge.altText} src={challenge.svg} style={{ width: "60px", maxHeight: "50px" }}></img>
                    <h1 className={styles.challengeWrapperText}>{challenge.title}</h1>
                  </div>
                  {userData.challengeActivities.filter((codes) => codes.code === challenge.code).length > 0 ? (
                    <button
                      tabIndex="0"
                      aria-label={`You have already completed this challenge today (${challenge.title}), come back tomorrow and complete it again!`}
                      data-tip={
                        "You have already completed this challenge today, come back tomorrow and complete it again! "
                      }
                      className={styles.completedTaskButton}
                    >
                      Completed
                    </button>
                  ) : (
                    <button
                      data-analyticsidentifier={"popup_" + challenge.code}
                      onKeyDownCapture={(e) => {
                        if (e.code === "Enter") completeAndPush(challenge);
                      }}
                      tabIndex="0"
                      aria-label={`${challenge.description}`}
                      data-tip={challenge.description}
                      className={styles.completeTaskButton}
                      onClick={(e) => {
                        if (e.code !== "Enter") {
                          setSelectedTask(challenge);
                          setToggle(true);
                        }
                      }}
                    >
                      Complete challenge
                    </button>
                  )}
                </div>
              );
            })}
        </div>
      </div>
      {userData.dailychallenge && !mobileVersion && <ReactTooltip multiline={true} />}
    </>
  );
}

export default DailyChallenge;

import React, { useState, useEffect } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { ProgressBar } from "primereact/progressbar";
import axios from "axios";

const Swagger = ({ title }) => {
  const [swagger, setSwagger] = useState({});

  useEffect(() => {
    (async () => {
      document.title = title;
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      const swagger = (await axios.get(`${process.env.REACT_APP_API_URL}/v1/openapi`)).data;
      swagger.servers = [{ url: `${process.env.REACT_APP_API_URL}/v1` }];
      setSwagger({
        ...swagger,
      });
    })();
  }, []);

  return (
    <>
      {!Object.keys(swagger).length ? (
        <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <ProgressBar mode="indeterminate" style={{ height: "6px", width: "80%" }} color="#D5AD3E" />
        </div>
      ) : (
        <SwaggerUI spec={swagger} />
      )}
    </>
  );
};
export default Swagger;

const monthAdd = (date, month) => {
  var temp = date;
  temp = new Date(date.getFullYear(), date.getMonth(), 1);
  temp.setMonth(temp.getMonth() + (month + 1));
  temp.setDate(temp.getDate() - 1);
  if (date.getDate() < temp.getDate()) {
    temp.setDate(date.getDate());
  }

  return temp;
};

export function StructorDate(dateObj) {
  const everyDatePointer = [];
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  for (let i = 1; i <= 12; i++) {
    const date = monthAdd(dateObj, i);
    var month = monthNames[date.getMonth()]; //months from 1-12
    var year = date.getUTCFullYear();

    let newdate = month + " " + year;
    everyDatePointer.push(newdate);
  }
  return everyDatePointer;
}

export function PlaceRightMonth(project, dateObj) {
  let newProjectAr = {};
  let dates = Object.keys(project["data"]);

  for (let i = 1; i <= 12; i++) {
    const date = monthAdd(dateObj, i);
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    const findDate = dates.find((date) => {
      return date.slice(0, 4) === year + "" && date.slice(5, 7) === month + "";
    });
    if (findDate) {
      newProjectAr[findDate] = project["data"][findDate];
    } else {
      newProjectAr[`${year}-${month}-01`] = {};
    }
  }

  return newProjectAr;
}

import styles from "../Components/css/sustainble.module.scss";
import SustainabilityListItem from "../Components/SustainabilityListItem.jsx";
import tipsList from "../content/sustainabilityTips.json";
import "primeflex/primeflex.css";
import Slack from "../Components/Slack";

const Tips = () => {
  return (
    <div className={`grid ${styles.fullWrapper}`} style={{ flexDirection: "column" }}>
      <div className={`col ${styles.wrapperTitleBig}`} style={{ margin: "0px 8px" }}>
        <h1 className={styles.titleBig}>Sustainability</h1>
      </div>
      <div className={`grid ${styles.gridContainer}`}>
        <div className={`col `} style={{ flexDirection: "column", width: "50%", height: "100%" }}>
          <div className={`grid `} style={{ flexDirection: "column", gap: "8px", height: "100%" }}>
            <div className={`grid ${styles.responsiveCSS} ${styles.leftTextContainer}`} style={{}}>
              <div className={`col ${styles.wrapper} ${styles.container} ${styles.contentBox}`}>
                <h1 tabIndex="0" className={styles.title}>
                  REN
                </h1>
                <p tabIndex="0" className={styles.text}>
                  Ren is a gamified application for motivating coworkers to make more environmental-friendly choices,
                  both in their professional and their daily life. Everybody can make a difference by changing the
                  little things. Of course, individual adaptations and improvements can not be seen as off-sets but the
                  combination of the individual and corporate effort can make a change on the overall environmental
                  impact. And who doesn't want to be a part of this movement? Start collecting pinecones and expirience
                  points today to make a change as an individual, as a team and as part of a company!
                </p>
                <p tabIndex="0" className={styles.text}>
                  Do you have any tips on making work more sustainable? <br /> For input, questions or discussions, you
                  can get in touch with us on{" "}
                  <a href="https://slack.com/app_redirect?channel=dt-edge-ren" target="_blank" rel="noreferrer">
                    Slack
                  </a>
                  .
                </p>
                <hr />
                <p tabIndex="0" className={styles.text}>
                  For IKEA sustainability means a better life for more people. Humanity faces drastic climate changes
                  and IKEA makes an active attempt on reducing its climate footprint. IKEA already is working heavily on
                  different aspects of{" "}
                  <a
                    className={styles.generalLink}
                    href="https://about.ikea.com/en/sustainability/sustainability-report-highlights"
                  >
                    sustainability
                  </a>
                  . Even though the digital services may not have the biggest impact on IKEAs footprint as other
                  departments, there is always room to make a positive change. Every kg of reduced CO2-emission is a
                  win, no matter if it is on individual, team or corporate-level.
                </p>
                <div className={`col ${styles.wrapper} ${styles.slackContainer}`}>
                  <Slack widePage={true} />
                </div>
              </div>
              <div
                className={`col ${styles.bigContainer}`}
                style={{ padding: "0px 0px", display: "flex", flexGrow: 3 }}
              >
                <div className={`col ${styles.wrapper} ${styles.tipsContainer}`}>
                  <div className={styles.titleWrapper}>
                    <h1 tabIndex="0" className={styles.title} style={{ marginLeft: "20px" }}>
                      Tips for green development
                    </h1>
                  </div>
                  <div className={styles.listContainer}>
                    {tipsList.sustainabilityTips.map((listItem, index) => {
                      return <SustainabilityListItem key={`${index}_tip${listItem.id}`} listItem={listItem} />;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tips;

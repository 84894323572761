import React from "react";
import styles from "../Components/css/loading.module.scss";

const Loading = (props) => {
  return (
    <div style={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <div
        style={{
          height: "100px",
          width: "100px",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className={styles.world} style={{ position: "absolute" }}>
          <img src={"startPage/world.svg"} alt="world" />
          <div style={{ position: "absolute" }}>
            <img
              alt="windTurbine"
              className={styles.windTurbine}
              src={"startPage/turbineHead.svg"}
              style={{ position: "relative", top: "-74px", left: "285px" }}
            />
            <img
              alt="windTurbine"
              className={styles.windTurbine}
              src={"startPage/turbineHead.svg"}
              style={{ position: "relative", top: "-80px", left: "272px" }}
            />
            <img
              alt="windTurbine"
              className={styles.windTurbine}
              src={"startPage/turbineHead.svg"}
              style={{ position: "relative", top: "-93px", left: "251px" }}
            />
          </div>
          <div style={{ position: "absolute" }}>
            <img
              alt="waterWheel"
              className={styles.waterWheel}
              src={"startPage/waterWheel.svg"}
              style={{ position: "relative", top: "-512px", left: "80px" }}
            />
            <img
              alt="waterWheel"
              className={styles.waterWheel}
              src={"startPage/waterWheel.svg"}
              style={{ position: "relative", top: "-482px", left: "-72px" }}
            />
          </div>
        </div>
        <img
          alt="spinWheel"
          className={styles.spinOne}
          src={"startPage/spinWheel_1.svg"}
          style={{ position: "absolute" }}
        />
        <img
          alt="spinWheel"
          className={styles.spinTwo}
          src={"startPage/spinWheel_2.svg"}
          style={{ position: "absolute" }}
        />
        <img
          alt="spinWheel"
          className={styles.spinThree}
          src={"startPage/spinWheel_3.svg"}
          style={{ position: "absolute" }}
        />
      </div>
    </div>
  );
};

export default Loading;

import styles from "./css/fullCompanyTimeLine.module.scss";
import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import LineChart from "./charts/LineChart";
import { StoreContext } from "../Context";

const FullCompanyTimeLine = (props) => {
  const {
    state: { userData },
    actions,
  } = useContext(StoreContext);
  const [ariaLabel, setAriaLabel] = useState("");

  useEffect(() => {
    if (userData.hasOwnProperty("ingkaCo2")) return;
    let oneYearBefore = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

    const fetchIngkaCo2 = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/v1/emissions/global?startDate=${oneYearBefore
            .toISOString()
            .substring(0, 10)}`
        )
        .then(
          (response) => {
            let dateAr = Object.keys(response.data);
            let copyAr = [];
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            dateAr.map((date) => {
              let newDate = new Date(date);
              const year = newDate.getFullYear();
              const month = newDate.getMonth();
              copyAr.push(year + " " + monthNames[month]);
            });
            actions.updateContext({
              ...userData,
              ingkaCo2: { data: response.data, date: copyAr },
            });
          },
          (error) => {
            console.log(error);
          }
        );
    };
    fetchIngkaCo2();
    //eslint-disable-next-line
  }, [userData]);

  useEffect(() => {
    if (userData.ingkaCo2) {
      let date = userData.ingkaCo2.date[userData.ingkaCo2.date.length - 1];
      let data =
        userData.ingkaCo2.data[Object.keys(userData.ingkaCo2.data)[Object.keys(userData.ingkaCo2.data).length - 1]]
          .carKmEquivalent;
      setAriaLabel(
        `This is a Graph of the GCP CO2 emissions on Ingka Digital - The last month recorded was ${date} and it produced CO2 emission equal to driving a car for ${data} km`
      );
    }
  }, [userData.ingkaCo2]);

  return (
    <div
      className={styles.wrapper}
      style={{
        height: "200px",
        padding: "0px 0px 30px 0px",
      }}
      tabIndex="0"
      aria-label={ariaLabel}
    >
      {userData.hasOwnProperty("ingkaCo2") && (
        <LineChart project={userData.ingkaCo2.data} everyDatePointer={userData.ingkaCo2.date} />
      )}
    </div>
  );
};

export default FullCompanyTimeLine;

import React, { useState, useEffect, useCallback } from "react";
import styles from "../Components/css/projectsDropDown.module.scss";
import ProjectDropDownItem from "../Components/ProjectDropDownItem";
import ProjectsOwnListItems from "../Components/ProjectsOwnListItems";

const ProjectsDropDown = ({ userData, clickedInsideSearch, updateUserProjects, userProjects, setUserProjects }) => {
  //#region Variables
  const [resultsArr, setResultsArr] = useState([]); // the array with the matching projects (to the searched term)
  const [searchInput, setSearchInput] = useState(""); // saves the input currently done
  const [searchedTerm, setSearchedTerm] = useState(""); // saves the searched term after the delay (needed to split actual inputval to avoid delay in typing)
  // const [gcpProjects, setGcpProjects] = useState([]); // all the projects, need to be saved to context
  const maxProjects = 10; // the max amount of projects that the user can choose
  const [toggleSearchBox, setToggleSearchBox] = useState(clickedInsideSearch); // toggles the searchbox visibility
  //#endregion

  //#region Gets the projects when site is loaded
  useEffect(() => {
    // if the user already has saved projects it will be set here, otherwise it is an empty array
    if (userData.projects) {
      setUserProjects(userData.projects);
      setResultsArr(() => {
        return resultsArr.filter((project) => !userProjects.includes(project));
      });
    }
    // eslint-disable-next-line
  }, [userData]);
  //#endregion

  // checks on every click and toggles the searchbox according
  useEffect(() => {
    if (clickedInsideSearch) {
      setToggleSearchBox(true);
    } else {
      setToggleSearchBox(false);
    }
    // eslint-disable-next-line
  }, [clickedInsideSearch]);

  // the delay function for the searchfunction
  const debounce = (callback, wait = 500) => {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  };

  // the debouncing function ONLY changes the searchedTerm
  // the filtering will be done in the useeffect reacting to searchedTerm, otherwise the filtering not finished before rendering
  // eslint-disable-next-line
  const searchResults = useCallback(
    debounce((inpt) => {
      setSearchedTerm(inpt); // sets the searched value
    }),
    []
  );

  useEffect(() => {
    // goes up to team.jsx for updating the userprojects, the boolean indicates if it is within the max-amount
    updateUserProjects(true, userProjects);
    //eslint-disable-next-line
  }, [userProjects]);

  // edits the list over projects(both resultsArr and own Projects)
  // gets info from the child (project-string with project name) and bool (true-added to own list, false-removed from own list)
  const editList = (project, bool) => {
    if (bool) {
      if (userProjects.length < maxProjects) {
        // add it to own list
        setUserProjects((prev) => {
          return [...prev, project];
        });
        // remove it from resultsarr
        setResultsArr((prev) => {
          return prev.filter((pro) => pro !== project);
        });
      } else {
        // if the max-amount is reached the team.jsx will trigger an overlay on every click
        updateUserProjects(false, userProjects);
      }
    } else {
      // removes from own list (will be added to results automatically)

      setUserProjects((prev) => {
        return prev.filter((pro) => pro !== project);
      });
    }
  };

  // handles the input
  const handleChange = (e) => {
    setSearchInput(e.target.value); //sets what is shown in the input
    setToggleSearchBox(true); // toggles the searchbox
    searchResults(e.target.value.toLowerCase()); //has a debounce to set the searched term
  };

  // on the change of the searched term (delayed) then the array with the results will be set
  useEffect(() => {
    // if the searchinput changes, set the resultsarr
    searchedTerm && searchedTerm.length > 0
      ? // sets the array with the matching results
        setResultsArr(() => {
          // also needs to check if the user has saved the result items in his own list and remove it in this case
          return userProjects && userProjects.length > 0
            ? userData.gcpProjectList &&
                userData.gcpProjectList
                  .filter((project) => project.includes(searchedTerm))
                  .filter((proj) => !userProjects.includes(proj))
            : userData.gcpProjectList && userData.gcpProjectList.filter((project) => project.includes(searchedTerm));
        })
      : setResultsArr([]);
    //eslint-disable-next-line
  }, [searchedTerm]);

  return (
    <div className={styles.projectsContainer}>
      <div className={styles.dropDownWrapper}>
        <div className={styles.searchInput}>
          <input
            autoComplete="off"
            onFocus={() => {
              setToggleSearchBox(true);
            }}
            id="projectsSearchBox"
            value={searchInput}
            onChange={(e) => {
              handleChange(e);
            }}
            type="search"
            placeholder="Search for projects"
          />
          <div
            className={`${styles.searchResultsContainer} ${
              searchInput === "" || !toggleSearchBox ? styles.resultsNoShow : styles.resultsShow
            }`}
          >
            {resultsArr?.length > 0 ? (
              resultsArr.map((proj, index) => {
                return (
                  <ProjectDropDownItem
                    setToggleSearchBox={setToggleSearchBox}
                    editList={editList}
                    key={`project_${index}`}
                    project={proj}
                  />
                );
              })
            ) : (
              <h5 className={styles.disable_select}>No results found for "{searchInput}"</h5>
            )}
          </div>
        </div>
      </div>
      <h5 className={`${styles.titleH5} ${styles.disable_select}`}>Your projects</h5>
      <div tabIndex="0" aria-label="List of your selected projects" className={styles.ownList}>
        {userProjects &&
          userProjects.length > 0 &&
          userProjects.map((project, index) => {
            return <ProjectsOwnListItems editList={editList} key={`project_${index}`} project={project} />;
          })}
      </div>
    </div>
  );
};

export default ProjectsDropDown;

import React, { useState, useContext, useEffect } from "react";
import styles from "./css/shopNavBar.module.scss";
import { StoreContext } from "../Context";
import axios from "axios";
import { logButton } from "../analytics";

const ShopNavBar = ({ setTryItem, user, items, categories }) => {
  // user contains the userData, // items contains the shopItems, // categories contains an array with the categories available // setTryItem contains the function to try the item on/to wear it
  // defines the default shown category of the shopItems
  const [selectItem, setSelectItem] = useState("glasses");
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [currentButton, setCurrentButton] = useState(false);
  const { actions } = useContext(StoreContext);

  const postToDB = async (updatedContent) => {
    setLoadingResponse(true);
    await axios.post(`${process.env.REACT_APP_API_URL}/v1/shop/item/buy`, { ItemId: updatedContent.itemId }).then(
      (response) => {
        if (response.status === 201) {
          setLoadingResponse(false);
          // updates the user locally
          actions.updateContext({
            ...user,
            pinecones: response.data.pinecones,
            inventory: [...user.inventory, response.data.item],
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const buyItem = (item) => {
    if (loadingResponse) {
      return;
    }
    setCurrentButton(item.itemId);
    // when user wants to buy, the item.cost needs to be compared to the available cones
    const enoughCones = user.pinecones >= item.cost;
    // if false => not enough money, return and notification to user
    if (!enoughCones) {
      alert("You don't have enough cones!");
      return;
    } else if (user.inventory.some((userItem) => userItem.itemId === item.itemId)) {
      alert("You already own this item");
    } else {
      // if true => enough money, post to DB and update userData,
      postToDB(item);
    }
  };
  // to set the item to be previewed on the avatar
  const previewItem = (item) => {
    setTryItem((prev) => {
      return {
        ...prev,
        [item.itemType]: item.itemId,
      };
    });
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div style={{ display: "flex", gap: "10px" }}>
          {categories &&
            categories.map((category, index) => {
              return (
                <button
                  aria-label={`Category ${category}`}
                  style={{ border: "none", color: "white", outline: "none" }}
                  tabIndex="0"
                  key={`${category}__${index}`}
                  className={`${styles.itemTitleSelector} ${category === selectItem && styles.selectedCategory}`}
                  onClick={() => setSelectItem(category)}
                >
                  {capitalizeFirstLetter(category)}
                </button>
              );
            })}
        </div>
        <div className={styles.shopWrapper}>
          <div style={{ display: "flex", gap: "10px", height: "100%" }}>
            {selectItem &&
              items
                .filter((itemObj) => itemObj.itemType === selectItem)
                .map((filteredItems, index) => {
                  return (
                    <div key={`${filteredItems}__${index}`}>
                      <div className={styles.imageWrapper} style={{ position: "relative" }}>
                        <img
                          className={styles.imageProfile}
                          alt={filteredItems.itemId}
                          src={`../Avatar/${selectItem}/${filteredItems.itemId}.svg`}
                          style={{ position: "absolute" }}
                        />
                        <img
                          className={styles.imageProfile}
                          alt="Boilerplate Avatar"
                          src={"../Avatar/plainCharacter.svg"}
                        />
                      </div>
                      <div className={styles.controllWrapper}>
                        <button
                          aria-label={`try ${filteredItems.itemType} item ${filteredItems.itemId}`}
                          data-analyticsidentifier={"testItem_" + filteredItems.itemId}
                          className={styles.previewWrapper}
                          onClick={(e) => {
                            previewItem(filteredItems);
                            logButton(e);
                          }}
                        >
                          <h1 style={{ pointerEvents: "none" }}>Try this out</h1>
                        </button>
                        {/* either the user already owns it, or the button will be shown */}
                        {user.inventory &&
                        user.inventory.filter((itemObj) => itemObj.itemId === filteredItems.itemId).length > 0 ? (
                          <h1 style={{ pointerEvents: "none" }}>You already own this</h1>
                        ) : (currentButton === filteredItems.itemId) & loadingResponse ? (
                          <img
                            alt={`Loading purchase`}
                            src={"../Icons/loading.svg"}
                            className={styles.loadingPurchase}
                          ></img>
                        ) : (
                          <button
                            aria-label={`buy ${filteredItems.itemType} item ${filteredItems.itemId}`}
                            data-analyticsidentifier={
                              user.pinecones >= filteredItems.cost
                                ? "boughtItem_" + filteredItems.itemId
                                : "notEnoughPinecones_" + filteredItems.itemId
                            }
                            className={styles.costWrapper}
                            onClick={(e) => {
                              buyItem(filteredItems);
                              logButton(e);
                            }}
                          >
                            <h1 style={{ pointerEvents: "none" }}>Buy for {filteredItems.cost}</h1>
                            <img
                              alt={`This item costs ${filteredItems.cost} pineCones`}
                              src={"../Icons/pinecone.svg"}
                              style={{ width: "20px", height: "20px", marginTop: "6px", marginLeft: "2px" }}
                            ></img>
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopNavBar;

import styles from "./css/ownedItems.module.scss";
import React, { useState, useEffect } from "react";
import { logButton } from "../analytics";

const OwnedItems = ({ item, wearTheItem, wearing }) => {
  // item = the current item from user.item
  // wearTheItem is a pointer to the function in the parent
  // wearing check if the user avatar is using
  const [wear, setWear] = useState();
  // if person wears it, change the text on the button
  const wearHandler = () => {
    wear ? setWear(false) : setWear(true);
  };
  //checks if there is an item worn by the avatar and changes the wear-state
  useEffect(() => {
    wearing.length ? setWear(true) : setWear(false);
  }, [wearing.length]);
  return (
    <>
      <div
        aria-label={wear ? "Take off " + item.itemId : "Wear item " + item.itemId}
        onKeyDownCapture={(e) => {
          if (e.key === "Enter") {
            e.stopPropagation();
            e.preventDefault();
            wearTheItem(item, wear);
            wearHandler();
            logButton(e);
          }
        }}
        tabIndex="0"
        className={`${wear ? styles.wearing : ""} ${styles.itemContainer}`}
      >
        <img
          alt={item.itemId}
          src={`../Avatar/${item.itemType}/${item.itemId}.svg`}
          className={styles.boilerPlate}
          style={{ position: "absolute" }}
        ></img>
        <img className={styles.boilerPlate} alt="Avatar Boiler Plate" src={"../Avatar/plainCharacter.svg"} />
        <div className={styles.overlay} style={{ position: "absolute" }}>
          {/* if the person wears it, change to take off */}
          {/* on click, send it upp to change in user */}
          <button
            data-analyticsidentifier={wear ? "remove_" + item.itemId : "wear_" + item.itemId}
            className={`${wear ? styles.wearing : ""} ${styles.wearBtn}`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              wearTheItem(item, wear);
              wearHandler();
              logButton(e);
            }}
          >
            {wear ? "Take off" : "Wear"}
          </button>
        </div>
      </div>
    </>
  );
};

export default OwnedItems;

import { isTablet, isBrowser, isMobileOnly } from "react-device-detect";
import axios from "axios";

let previousLocation = undefined;
let previousButton = undefined;

const logAnalytics = async (targetUrl, targetButton) => {
  const navigationState = {
    userId: sessionStorage.getItem("userOid"),
    internalReferrer: previousLocation ? previousLocation : null,
    path: window.location.pathname,
    targetUrl: targetUrl ? targetUrl : null,
    timestamp: new Date(),
    deviceType: isMobileOnly ? "mobile" : isTablet ? "tablet" : isBrowser ? "desktop" : "unknown",
    element: targetButton ? targetButton : null,
  };
  previousLocation = window.location.pathname;
  await axios.post(`${process.env.REACT_APP_API_URL}/v1/analytics`, [navigationState]);
};

const logExternalLink = async (e) => {
  e.preventDefault();
  window.open(e.target.href, "_blank");
  await logAnalytics(e.target.href, null);
};

const logButton = async (e, analyticsidentifier) => {
  const targetID = analyticsidentifier ? analyticsidentifier : e.target.getAttribute("data-analyticsidentifier");
  if (previousButton === targetID) return;
  e.preventDefault();
  await logAnalytics(null, targetID);
  previousButton = targetID;
};

export { logAnalytics, logExternalLink, logButton };

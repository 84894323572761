import styles from "./css/dailyQuestion.module.scss";
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { StoreContext } from "../Context";
import ChallengePopUp from "../Components/ChallengePopUp";
import { logButton } from "../analytics";

const DailyQuestion = () => {
  const {
    state: { userData },
  } = useContext(StoreContext);
  const [startChallenge, setStartChallenge] = useState(false);
  const [question, setQuestion] = useState();
  const [time, setTime] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [triviaExist, setTriviaExist] = useState(false);
  const [answerResponse, setAnswerResponse] = useState(0);

  useEffect(() => {
    if (userData.triviaActivities && userData.triviaActivities.length > 0) {
      setTriviaExist(true);
    }
    const fetchDailyQuestion = async () => {
      try {
        const dailyQuestion = (await axios.get(`${process.env.REACT_APP_API_URL}/v1/trivia/question/daily`)).data;
        setQuestion(dailyQuestion);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDailyQuestion();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {startChallenge && (
        <ChallengePopUp
          setAnswerResponse={setAnswerResponse}
          answerResponse={answerResponse}
          question={question}
          time={time}
          toggle={toggle}
          triviaExist={triviaExist}
          setToggle={setToggle}
        />
      )}
      <div
        className={styles.wrapper}
        tabIndex="0"
        aria-label="The daily question feature is not accessible on a screen reader for now."
      >
        <h1 className={styles.title}>Daily Question</h1>
        <button
          data-analyticsidentifier={
            triviaExist || answerResponse !== 0 ? "alreadyAnswered_dailyChallenge" : "startingChallenge_dailyChallenge"
          }
          className={styles.startQuestionButton}
          tabIndex="-1"
          onClick={(e) => {
            logButton(e);
            setStartChallenge(true);
            setTime(Date.now() + 10000);
            setToggle(true);
          }}
        >
          Challenge me
        </button>
      </div>
    </>
  );
};
export default DailyQuestion;

import LineChartProjects from "./charts/LineChartProjects";
import { StructorDate, PlaceRightMonth } from "./charts/CalcRightMonth";

const ProjectTimeLine = ({ project, userProjects, dateObj }) => {
  let sortProject = {};
  let everyDatePointer = StructorDate(dateObj);

  for (let i = 0; userProjects.length > i; i++) {
    if (project[userProjects[i]] === undefined) {
      continue;
    } else {
      sortProject[userProjects[i]] = { data: PlaceRightMonth(project[userProjects[i]], dateObj) };
    }
  }

  return (
    <>
      <div
        style={{
          height: "350px",
          padding: "0px 0px 30px 0px",
        }}
        tabIndex="0"
        aria-label="This is a graph of the CO2 emission of your selected projects."
      >
        <LineChartProjects project={sortProject} everyDatePointer={everyDatePointer} />
      </div>
    </>
  );
};
export default ProjectTimeLine;

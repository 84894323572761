import React, { useEffect } from "react";
import { ProgressBar } from "primereact/progressbar";

import { MsalProvider, useMsal } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { msalInstance } from "../../services/msal";

const SignIn = () => {
  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
    if (instance.getAllAccounts().length === 0 && inProgress === "none") {
      instance.loginRedirect();
    }
  }, [instance, accounts, inProgress]);
  return null;
};

const Authentication = ({ children }) => {
  return (
    <MsalProvider instance={msalInstance}>
      <SignIn />
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <div
          style={{ height: "100vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <ProgressBar mode="indeterminate" style={{ height: "6px", width: "80%" }} color="#D5AD3E" />
        </div>
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
};

export default Authentication;

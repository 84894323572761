import styles from "./css/progresTree.module.scss";
import React, { useContext } from "react";
import { StoreContext } from "../Context";
import TreeImage from "./TreeImage";

const ProgresTree = () => {
  const {
    state: { userData },
  } = useContext(StoreContext);

  return (
    <div className={styles.wrapper} tabIndex="0" aria-label="This is a visualization of a tree representing your level">
      <h1>Progress tree</h1>
      <div>
        <TreeImage userInformation={userData} />
      </div>
    </div>
  );
};

export default ProgresTree;

import styles from "./css/navbar.module.scss";
import React from "react";
import { Link } from "react-router-dom";

const Navbar = ({ linkList }) => {
  return (
    <nav tabIndex="0" role="navigation">
      <div id={styles.navContainer}>
        {linkList.map((listItem, index) => {
          return (
            <div key={`link${index}`}>
              <Link to={listItem.path}>
                <img className={styles.navIcons} alt={listItem.alt} src={listItem.image} />
              </Link>
              <div className={styles.closedNav}>{listItem.title}</div>
            </div>
          );
        })}
        <div key={`link_slack`}>
          <a href="slack://channel?team=TMEDJTBJ4&id=C03ADK21140">
            <img className={styles.slackIcon} alt={"Slack"} src={"../Icons/slack_color.svg"} />
          </a>
          <div className={styles.closedNav}>{"Connect to us on slack"}</div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

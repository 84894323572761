import React, { useContext, useEffect, useState } from "react";
import styles from "./css/userPreview.module.scss";
import { StoreContext } from "../Context";

const UserPreview = () => {
  const {
    state: { userData },
  } = useContext(StoreContext);
  const [exp, setExp] = useState();

  useEffect(() => {
    setExp(Math.floor(Math.pow(1.5, userData.level - 1) * 100));
  }, [userData]);
  return (
    <>
      {Object.keys(userData).length !== 0 && (
        <span className={styles.wrapper}>
          <div style={{ position: "absolute" }}>
            {userData.avatar && (
              <div
                className={styles.profileImageWrapper}
                tabIndex="0"
                aria-label={`Profil picture, you have a head of a ${userData.avatar.head} and a body of a ${userData.avatar.body}`}
              >
                {userData.avatar.body && (
                  <img
                    alt={`A ${userData.avatar.body}'s body`}
                    src={`../Avatar/body/${userData.avatar.body}.svg`}
                    style={{ position: "absolute" }}
                  ></img>
                )}
                {userData.avatar.head && (
                  <img
                    alt={`A ${userData.avatar.head}'s head`}
                    src={`../Avatar/head/${userData.avatar.head}.svg`}
                    style={{ position: "absolute" }}
                  ></img>
                )}
                {userData.avatar.glasses && (
                  <img
                    alt={`${userData.avatar.glasses}`}
                    src={`../Avatar/glasses/${userData.avatar.glasses}.svg`}
                    style={{ position: "absolute" }}
                  ></img>
                )}
                {userData.avatar.hat && (
                  <img
                    alt={`${userData.avatar.hat}`}
                    src={`../Avatar/hat/${userData.avatar.hat}.svg`}
                    style={{ position: "absolute" }}
                  ></img>
                )}
                {userData.avatar.mouth && (
                  <img
                    alt={`${userData.avatar.glasses}`}
                    src={`../Avatar/mouth/${userData.avatar.mouth}.svg`}
                    style={{ position: "absolute" }}
                  ></img>
                )}
              </div>
            )}
          </div>
          <div className={styles.infoWrapper}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ display: "flex", flexDirection: "column", flexGrow: "1" }}>
                <h1 className={styles.userName}>
                  {userData.givenName} {userData.surname}
                </h1>
                <h2 className={styles.cdsLocation}>{userData.officeLocation} </h2>
              </div>
            </div>
            <div className={styles.expWrapper}>
              <div className={styles.pineConeWrapper}>
                <p className={styles.expCount}>
                  {userData.experience}/{exp}xp
                </p>
                <div>
                  <img alt="Pinecone Icon" src={"../Icons/pinecone.svg"}></img>
                  <p>{userData.pinecones}</p>
                </div>
              </div>
              <div className={styles.expBar}>
                <div className={styles.noExp}></div>
                <div
                  tabIndex="0"
                  aria-label={`Your experience is ${Math.floor((userData.experience / exp) * 100)}% to level ${
                    userData.level + 1
                  }`}
                  className={styles.exp}
                  style={{ width: (userData.experience / exp) * 100 + "%" }}
                ></div>
              </div>
              <div className={styles.levelwrapper}>
                <div className={styles.level} tabIndex="0" aria-label={`Your level is ${userData.level}`}>
                  {userData.level}
                </div>
              </div>
            </div>
          </div>
        </span>
      )}
    </>
  );
};

export default UserPreview;

import React, { useContext, useEffect, useState, useLayoutEffect } from "react";
import { StoreContext } from "./Context";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Home from "./Views/Home";
// import User from "./Views/User";
import Projects from "./Views/Projects";
import Sustainable from "./Views/Sustainable";
import Shop from "./Views/Shop";
import Swagger from "./Views/Swagger";
import Changelog from "./Views/Changelog";
import "primeflex/primeflex.css";
import { logAnalytics } from "./analytics";

const PathRoutes = ({ width }) => {
  const location = useLocation();
  const [path, setPath] = useState();

  useEffect(() => {
    (async () => {
      if (window.location.pathname === path) return;
      setPath(location.pathname);
      await logAnalytics();
    })();
    //eslint-disable-next-line
  }, [location]);

  return (
    <Routes>
      {/* The routes are dynamic routes, so the component in JSX is passed into it by writing element={<Welcome/>} as a prop */}
      <Route path="/" element={<Home width={width} />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/shop" element={<Shop />} />
      <Route path="/sustainability" element={<Sustainable />} />
      <Route path="/changelog" element={<Changelog />} />
      <Route path="/swagger" element={<Swagger title="REN API Specification" />} />
    </Routes>
  );
};
export default PathRoutes;

import styles from "./css/codeSustainable.module.scss";
import React from "react";
import { useNavigate } from "react-router-dom";

const CodeSustainable = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div>
        <h1 tabIndex="0" className={styles.title}>
          Green development
        </h1>
        <p tabIndex="0" className={styles.text}>
          Did you know about the environmental impact of your daily choices? Every choice we make, whether it is in our
          day-to-day life or in our work environment, provides an opportunity to positively affect our environment. We
          have listed some best practices for green development.
        </p>
        <button tabIndex="0" className={styles.learnMore} onClick={() => navigate("/sustainability")}>
          Learn more
        </button>
      </div>
      <div className={styles.treeWrapper} tabIndex="-1">
        <img tabIndex="-1" alt="Tree" className={styles.tree} src={"../tree/treeTest.svg"}></img>
      </div>
    </div>
  );
};

export default CodeSustainable;

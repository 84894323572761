import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineChart = ({ project, everyDatePointer }) => {
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Ingka Digital - GCP CO2 emissions",
        color: "white",
        font: {
          size: 25,
          family: "Oregano",
        },
      },
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        ticks: {
          color: "white",
          font: {
            family: "Oregano",
          },
        },
      },
    },
  };

  const data = {
    labels: everyDatePointer,
    datasets: Object.keys(project).map((data, index) => {
      let borderColor, backgroundColor;
      switch (index) {
        case 0:
          borderColor = "rgb(255, 99, 132)";
          backgroundColor = "rgba(255, 99, 132, 0.5)";
          break;
        case 1:
          borderColor = "rgb(53, 162, 235)";
          backgroundColor = "rgba(53, 162, 235, 0.5)";
          break;
        default:
          borderColor = "rgb(255, 99, 132)";
          backgroundColor = "rgba(255, 99, 132, 0.5)";
          break;
      }
      return {
        label: data,
        data: Object.values(project).map((x) => {
          return x.beefEquivalent;
        }),
        borderColor: borderColor,
        backgroundColor: backgroundColor,
      };
    }),
  };

  return (
    <div style={{ height: "100%", position: "relative", marginBottom: "1%", padding: "1%" }}>
      <Line options={options} data={data} />
    </div>
  );
};

export default LineChart;

import React, { useContext, useState, useEffect } from "react";
import { StoreContext } from "../Context";
import styles from "../Components/css/shop.module.scss";
import UserCharacterPreview from "../Components/UserCharacterPreview";
import ShopNavbar from "../Components/ShopNavbar";
import OwnedItem from "../Components/OwnedItems";
import "primeflex/primeflex.css";
import axios from "axios";

const Shop = () => {
  const {
    state: { userData },
    actions,
  } = useContext(StoreContext);
  const [loadingResp, setLoadingResp] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [itemCategories, setItemCategories] = useState();

  // to try the item before buying
  const [tryItem, setTryItem] = useState({ hat: "", mouth: "", glasses: "" });
  // to toggle if the category-btns are shown or the results
  const [showCategory, setShowCategory] = useState(true);
  // to show the results according to the chosen category
  const [filterBy, setFilterBy] = useState(false);
  // to update the avatar when chosing other clothes to wear
  const [updateAvatar, setUpdateAvatar] = useState(userData);
  const [noChange, setNoChange] = useState(true);

  // gets all the items
  useEffect(() => {
    if (userData.shop) {
      return;
    }
    const fetchShopItems = async () => {
      try {
        const itemsInStore = (await axios.get(`${process.env.REACT_APP_API_URL}/v1/shop/items`)).data;
        actions.updateContext({
          ...userData,
          shop: itemsInStore,
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchShopItems();
    //eslint-disable-next-line
  }, []);

  //creates the array with all the available categories in inventory & shopData
  const createCategoryArr = (arr) => {
    const typesArr =
      arr &&
      arr.map((item) => {
        return item.itemType;
      });
    const categoryArr = new Set(typesArr);
    return Array.from(categoryArr);
  };

  //sets the categories for the owned items
  useEffect(() => {
    setItemCategories(() => {
      return createCategoryArr(userData.inventory);
    });
  }, [userData]);

  // sets the chosen filter category and if it should be shown (toggle)
  const showOwnedItems = (filterCategory) => {
    showCategory ? setShowCategory(false) : setShowCategory(true);
    setFilterBy(filterCategory);
  };

  // wearing an item or taking off
  const wearItem = (item, wearing) => {
    // add the worn item to userdata, else remove (false)
    let wearOrRemove = !wearing ? item : "";
    // updates the local copy of the userData (because it shouldn't be changed in userData until the save button (clickHandlerUpdate) is pressed)
    setUpdateAvatar((prev) => {
      return {
        ...prev,
        avatar: {
          ...prev.avatar,
          [item.itemType]: wearOrRemove ? wearOrRemove.itemId : "",
        },
      };
    });
    // show the chosen item on the useravatar or removes it, when the items in the shop are changed (from type to itemType this needs to be changed as well)
    setTryItem((prev) => {
      return {
        ...prev,
        [item.itemType]: wearOrRemove ? wearOrRemove.itemId : "",
      };
    });
  };

  // checks if the avatar looks the same
  useEffect(() => {
    if (
      userData.avatar.glasses === updateAvatar.avatar.glasses &&
      userData.avatar.mouth === updateAvatar.avatar.mouth &&
      userData.avatar.hat === updateAvatar.avatar.hat
    ) {
      setNoChange(true);
    } else {
      setNoChange(false);
    }
    //eslint-disable-next-line
  }, [tryItem]);

  // updates the updateavatar if item bought
  useEffect(() => {
    setUpdateAvatar((prev) => {
      return {
        ...prev,
        inventory: userData.inventory,
      };
    });
    //eslint-disable-next-line
  }, [userData]);

  // the save-button for the updated Avatar, save to DB and to userData
  const clickHandlerUpdate = async () => {
    if (saveSuccess || saveError || loadingResp) return;
    setLoadingResp(true);
    await axios
      .patch(`${process.env.REACT_APP_API_URL}/v1/player`, {
        username: userData.userPrincipalName,
        avatar: updateAvatar.avatar,
      })
      .then(
        (response) => {
          if (response.status === 200) {
            setLoadingResp(false);
            setSaveSuccess(true);
            setTimeout(() => {
              setSaveSuccess(false);
            }, 2000);
            // updates the user locally
            actions.updateContext({
              ...userData,
              avatar: response.data.avatar,
            });
            setUpdateAvatar((prev) => {
              return {
                ...prev,
                avatar: response.data.avatar,
              };
            });
          }
        },
        (error) => {
          setLoadingResp(false);
          setSaveError(true);
          setTimeout(() => {
            setSaveError(false);
          }, 2000);
          console.log(error);
        }
      );
    setNoChange(true);
  };

  return (
    <div className={`${styles.fullWrapper}`} style={{ flexDirection: "column" }}>
      <div className={`col ${styles.wrapperTitleBig}`} style={{ margin: "0px 8px" }}>
        <h1 className={styles.titleBig}>Shop</h1>
      </div>
      <div className={`grid ${styles.responsiveCSS}`} style={{ gap: "10px", margin: "8px 8px" }}>
        <div className={`grid`} style={{ flexGrow: 1, flexDirection: "column", gap: "8px" }}>
          <div className={`col ${styles.wrapper} ${styles.teamWrapper}`}>
            <div style={{ height: "400px", backgroundColor: "white", borderRadius: "20px" }}>
              <UserCharacterPreview
                user={updateAvatar}
                tryItem={tryItem}
                updating={clickHandlerUpdate}
                loadingResp={loadingResp}
                saveSuccess={saveSuccess}
                saveError={saveError}
                noChange={noChange}
                pinecones={userData.pinecones}
              />
            </div>
          </div>
        </div>
        <div className={`col ${styles.wrapper}`} style={{ flexGrow: 3 }}>
          <h1 className={styles.titleSmall}></h1>
          {userData.shop && (
            <ShopNavbar
              categories={createCategoryArr(userData.shop)}
              items={userData.shop}
              user={userData}
              setTryItem={setTryItem}
            />
          )}
        </div>
      </div>
      <div className={`grid ${styles.categoriesWrapper}`} style={{ gap: "10px", margin: "8px 8px" }}>
        <div
          className={`col ${styles.wrapper}`}
          style={{ display: "flex", height: "200px", width: "100vw", boxSizing: "border-box", overflowY: "auto" }}
        >
          <div className={styles.categoryBoxContainer}>
            <button
              className={`${showCategory ? styles.categoryNoShow : styles.categoryBack} ${styles.categoryBox} ${
                styles.buttonStyle
              }`}
              onClick={(e) => {
                showOwnedItems();
              }}
            >
              <img alt={`Back Icon`} className={styles.categoryIcons} src={`Icons/back.svg`}></img>
            </button>
            {itemCategories &&
              itemCategories.map((category, index) => {
                return (
                  <button
                    aria-label={`Your ${category} items`}
                    key={`item_${category}`}
                    className={`${showCategory ? styles.categoryBox : styles.categoryNoShow} ${styles.buttonStyle}`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      showOwnedItems(category);
                    }}
                  >
                    <img alt={`${category} Icon`} className={styles.categoryIcons} src={`Icons/${category}.svg`}></img>
                    <p>{category}</p>
                  </button>
                );
              })}
          </div>
          <div className={showCategory ? styles.categoryNoShow : styles.ownedItemsContainer}>
            {/* if there is an items-arr, Filter the array of items and for each item that has the filteredItemCategory, return an OwnedItem-component*/}
            {userData.inventory.length > 0 &&
              userData.inventory
                .filter((itemObj) => itemObj.itemType === filterBy)
                .map((filteredItems, index) => {
                  return (
                    // wearItem is the clickhandler to put it on and wearing should send a list with the objects wearing
                    <OwnedItem
                      key={`${filteredItems.itemId}__${index}`}
                      item={filteredItems}
                      wearTheItem={wearItem}
                      wearing={Object.values(updateAvatar.avatar).filter(
                        (avatarObj) => filteredItems.itemId === avatarObj
                      )}
                    />
                  );
                })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shop;

import React from "react";
import styles from "../Components/css/projectsOwnListItems.module.scss";
import { logButton } from "../analytics";

const ProjectsOwnListItems = ({ editList, project }) => {
  return (
    <button
      tabIndex="0"
      aria-label={`click to remove ${project} from your project list `}
      data-analyticsidentifier={`remove_${project}`}
      onClick={(e) => {
        editList(project, false);
        logButton(e);
      }}
      className={`${styles.ownItems} ${styles.disable_select}`}
    >
      {project}
      <div className={styles.itemOverlay} style={{ pointerEvents: "none" }}>
        <h5 className={styles.disable_select}>Remove</h5>
      </div>
    </button>
  );
};

export default ProjectsOwnListItems;

import styles from "./css/slack.module.scss";

import { logButton } from "../analytics";

const Slack = ({ widePage }) => {
  const handleParent = () => {
    window.location.href = "slack://channel?team=TMEDJTBJ4&id=C03ADK21140";
  };
  const handleChild = (event) => {
    event.stopPropagation();
    event.target.select();
  };

  return (
    <div className={styles.wrapper} tabIndex="0">
      <button
        aria-label="Join us on Slack"
        style={{ background: "none" }}
        data-analyticsidentifier="slack"
        onClick={(e) => {
          handleParent();
          logButton(e, "slack");
        }}
        className={widePage ? `${styles.linkWrapper} ${styles.widePageLinkWrapper}` : `${styles.linkWrapper}`}
      >
        <div className={styles.textWrapper}>
          <div className={styles.title}>Join us on Slack</div>
          <input className={styles.channel} type="text" defaultValue="#dt-edge-ren" onClick={handleChild} />
        </div>
        <img className={styles.icon} src={"../Icons/slack_color.svg"} alt="Icon of Slack" />
      </button>
    </div>
  );
};

export default Slack;

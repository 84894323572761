import UserPreview from "../Components/UserPreview";
import DailyChallenge from "../Components/DailyChallenge";
import CodeSustainable from "../Components/CodeSustainable";
import DailyQuestion from "../Components/DailyQuestion";
import DidYouKnow from "../Components/DidYouKnow";
import Slack from "../Components/Slack";
import ProgresTree from "../Components/ProgresTree";
import FullCompanyTimeLine from "../Components/FullCompanyTimeLine";
import SustainabilityFacts from "../content/sustainabilityFacts.json";
import "primeflex/primeflex.css";
import { useEffect, useState } from "react";

const Home = ({ width }) => {
  const [randomNumber, setRandomNumber] = useState(0);
  const maxNr = SustainabilityFacts.sustainabilityFacts.length - 1;

  useEffect(() => {
    let randomNr = Math.floor(Math.random(0, maxNr) * 10);
    setRandomNumber(() => {
      return randomNr < 9 ? randomNr : 0;
    });
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="grid noSel" style={{ width: "100%" }}>
        <div className="grid" style={{ flexDirection: "column", width: "300px" }}>
          <div className="col">
            <UserPreview />
          </div>
          <div className="col">
            <ProgresTree />
          </div>
        </div>
        <div className="grid" style={{ flexDirection: "column", flexBasis: "0px", flexGrow: "1" }}>
          <div className="col">
            <CodeSustainable />
          </div>
          <div className="col">
            <DailyChallenge />
          </div>
        </div>
        <div
          className="grid"
          style={width > 1355 ? { width: "300px", flexDirection: "column", display: "inline-block" } : {}}
        >
          <div className="col">
            <DidYouKnow fact={SustainabilityFacts.sustainabilityFacts[randomNumber]} />
          </div>
          <div className="col">
            <DailyQuestion />
          </div>
          <div className="col">
            <Slack widePage={false} />
          </div>
        </div>
      </div>
      <div className="grid noSel" style={width > 1355 ? {} : { width: "100vw" }}>
        <div className="col">
          <FullCompanyTimeLine />
        </div>
      </div>
    </>
  );
};

export default Home;

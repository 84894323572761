import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "../Components/css/projects.module.scss";
import "primeflex/primeflex.css";
import axios from "axios";
import TeamAvatarView from "../Components/TeamAvatarView";
import { StoreContext } from "../Context";
import ProjectsDropDown from "../Components/ProjectsDropDown";
import ProjectTimeLine from "../Components/ProjectTimeLine";
import EqualToProjectEmission from "../Components/EqualToProjectEmission";
import { logButton } from "../analytics";

const Projects = () => {
  const {
    state: { userData },
    actions,
  } = useContext(StoreContext);
  const [reachedMax, setReachedMax] = useState(false); // if the max amount of projects is reached
  const [loadingProjectsResp, setLoadingProjectsResp] = useState(false); // the loading for PATCH
  const [clickedInsideSearch, setClickedInsideSearch] = useState(); // checks if the click was made in the searchbox // this only updates when changed, if it is the same, it will not change --> will make 2 clickstates
  const [clickToggle, setClickToggle] = useState();
  const [userProjects, setUserProjects] = useState([]); // the saved projects
  var dateObj = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

  useEffect(() => {
    const fetchTeamData = async () => {
      let userObj = {};
      try {
        if (!userData.hasOwnProperty("team")) {
          const team = (
            await axios.get(`${process.env.REACT_APP_API_URL}/v1/player/team/${userData.manager.userPrincipalName}`)
          ).data;
          userObj["team"] = team;
        }
        if (!userData.hasOwnProperty("gcpProjectList")) {
          const gcpProjectList = (await axios.get(`${process.env.REACT_APP_API_URL}/v1/project/all-project-ids`)).data;
          userObj["gcpProjectList"] = gcpProjectList;
        }
        if (!userData.hasOwnProperty("projectData") && userData.hasOwnProperty("projects")) {
          const projectData = (await axios.get(`${process.env.REACT_APP_API_URL}${createUrlPath(userData.projects)}`))
            .data;
          Object.keys(projectData).map((x) => {
            return (projectData[x] = { data: projectData[x], color: getRandomColor(), projectName: x });
          });
          userObj["projectData"] = { ...projectData };
        }
        actions.updateContext({
          ...userData,
          ...userObj,
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchTeamData();
    //eslint-disable-next-line
  }, []);

  const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const createUrlPath = (projectList) => {
    let path = "/v1/emissions/projects";
    for (let i = 0; projectList.length > i; i++) {
      if (i === 0) {
        path += "?gcpProjectId=" + projectList[i];
      } else {
        path += "&gcpProjectId=" + projectList[i];
      }
      if (projectList.length - 1 === i) {
        dateObj = dateObj.toISOString().substring(0, 10);
        path += "&startDate=" + dateObj;
      }
    }
    return path;
  };
  // updates the player in the db with the own projects
  const patchPlayerProjects = async (updatedContent, proj) => {
    setLoadingProjectsResp(true);
    try {
      if (proj.length > 0) {
        const projects = (
          await axios.patch(`${process.env.REACT_APP_API_URL}/v1/player`, {
            username: updatedContent.username,
            projects: updatedContent.projects,
          })
        ).data;
        const projectData = (await axios.get(`${process.env.REACT_APP_API_URL}${createUrlPath(proj)}`)).data;
        Object.keys(projectData).map((x) => {
          return (projectData[x] = { data: projectData[x], color: getRandomColor(), projectName: x });
        });
        actions.updateContext({
          ...userData,
          projects: projects.projects,
          projectData: { ...projectData },
        });
      } else {
        const projects = (
          await axios.patch(`${process.env.REACT_APP_API_URL}/v1/player`, {
            username: updatedContent.username,
            projects: updatedContent.projects,
          })
        ).data;
        actions.updateContext({
          ...userData,
          projects: projects.projects,
        });
      }
      setLoadingProjectsResp(false);
    } catch (error) {
      console.log(error);
    }
  };

  // click handler to save the chosen projects
  const saveSelection = async () => {
    if (userProjects !== userData.projects) {
      let proj = userProjects ? userProjects : [];
      const updatedUser = {
        username: userData.userPrincipalName,
        projects: proj,
      };
      patchPlayerProjects(updatedUser, proj);
    } else {
      setLoadingProjectsResp(true);
      setTimeout(() => {
        setLoadingProjectsResp(false);
      }, 1000);
    }
  };

  // gets the list of the userprojects from the dropdown-component and saves it to useState
  const updateUserProjects = (belowMax, userProjectsList) => {
    if (!belowMax) {
      // toggles the overlay with a timeout of 2sec
      setReachedMax(true);
      setTimeout(() => {
        setReachedMax(false);
      }, 2000);
    }
    setUserProjects(() => {
      return userProjectsList;
    });
  };

  // checks if click was made within the searchbox
  // still not working correctly, only recognized when searchitem being clicked
  const checkClick = (e) => {
    let classListArr = Array.from(e.target.classList);
    if (classListArr[0] === "s_item") {
      setClickedInsideSearch(true);
    } else {
      setClickedInsideSearch(false);
    }
  };

  // rerenders depending on where the click was made
  useEffect(() => {
    setClickToggle(clickedInsideSearch);
  }, [clickedInsideSearch]);

  return (
    <div
      onClick={(e) => {
        checkClick(e);
      }}
      className={`grid ${styles.fullWrapper}`}
      style={{ flexDirection: "column" }}
      id="projectsPageWrap"
    >
      <div className={`col ${styles.wrapperTitleBig}`} style={{ margin: "0px 8px" }}>
        <h1 className={`${styles.titleBig} `}>Projects</h1>
      </div>
      <div className={`grid ${styles.responsiveCSS}`} style={{ gap: "10px", margin: "8px 8px" }}>
        <div className={`grid`} style={{ flexDirection: "column", gap: "8px", width: "900px", flexGrow: "1" }}>
          <div className={`col ${styles.wrapper}`} style={{ flexGrow: "0" }}>
            <h1
              className={`${styles.titleSmall}`}
              style={{
                textAlign: "center",
                position: "absolute",
                color: "#26263F",
                zIndex: 1000,
                top: "5px",
                left: "30px",
              }}
            >
              Team View
            </h1>
            <TeamAvatarView team={userData.team} />
          </div>
          <div className={`col ${styles.wrapper}`}>
            {userData.projectData ? (
              <ProjectTimeLine project={userData.projectData} userProjects={userProjects} dateObj={dateObj} />
            ) : (
              <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                No Project Selected
              </div>
            )}
          </div>
        </div>
        <div className={`grid`} style={{ flexDirection: "column", gap: "8px", flexGrow: "1" }}>
          <div className={`col ${styles.wrapper}`}>
            <div className={styles.saveSelectionContainer}>
              <h1 className={`${styles.titleSmall}`}>Projects</h1>
              <Link className={styles.sustLink} to={"/sustainability"}>
                Tips on how to reduce emissions.
              </Link>
            </div>
            <ProjectsDropDown
              clickedInsideSearch={clickToggle}
              userData={userData}
              updateUserProjects={updateUserProjects}
              userProjects={userProjects}
              setUserProjects={setUserProjects}
            />
            <button
              data-analyticsidentifier="saveprojects"
              className={styles.updateProjectsBtn}
              onClick={(e) => {
                saveSelection();
                logButton(e);
              }}
            >
              Save selection
            </button>
            <div className={reachedMax || loadingProjectsResp ? styles.overlay : styles.noOverlay}>
              <div className={styles.overlayModal}>
                {reachedMax ? (
                  <p>You have reached the maximum amount of 10 monitored projects</p>
                ) : (
                  <p>Your selection has been saved</p>
                )}
              </div>
            </div>
          </div>
          <div className={`col ${styles.wrapper}`} tabIndex="0">
            {userData.projectData ? (
              <EqualToProjectEmission project={userData.projectData} userProjects={userProjects} />
            ) : (
              <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                No Project Selected
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;

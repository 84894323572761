import styles from "./css/didYouKnow.module.scss";
import { logExternalLink } from "../analytics";

const DidYouKnow = ({ fact }) => {
  return (
    <a
      onClick={(e) => {
        logExternalLink(e);
      }}
      className="generalLink"
      href={fact.srcURL}
    >
      <div className={styles.wrapper} style={{ pointerEvents: "none" }}>
        <h1 className={styles.title}>Did you know...</h1>
        <p className={styles.text}>{fact.description}</p>
      </div>
    </a>
  );
};

export default DidYouKnow;

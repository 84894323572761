import React, { useEffect } from "react";
import styles from "./css/userCharacterPreview.module.scss";
import { logButton } from "../analytics";

const UserCharacterPreview = ({
  tryItem,
  user,
  updating,
  loadingResp,
  saveSuccess,
  saveError,
  noChange,
  pinecones,
}) => {
  const feedbackText = () => {
    if (saveError) {
      return "Something went wrong";
    } else if (saveSuccess) {
      return "Avatar saved";
    } else {
      return "Save avatar";
    }
  };

  return (
    <>
      {Object.keys(user).length !== 0 && (
        <div className={styles.updateAvatarContainer}>
          <div className={styles.wrapper}>
            {user.avatar.body && (
              <img
                alt={`A ${user.avatar.body}'s body`}
                src={`../Avatar/body/${user.avatar.body}.svg`}
                style={{ position: "absolute" }}
              ></img>
            )}
            {user.avatar.head && (
              <img
                alt={`A ${user.avatar.head}'s head`}
                src={`../Avatar/head/${user.avatar.head}.svg`}
                style={{ position: "absolute" }}
              ></img>
            )}
            {tryItem.glasses ? (
              <img
                alt={`${tryItem.glasses}`}
                src={`../Avatar/glasses/${tryItem.glasses}.svg`}
                style={{ position: "absolute" }}
              ></img>
            ) : (
              user.avatar.glasses && (
                <img
                  alt={`${user.avatar.glasses}`}
                  src={`../Avatar/glasses/${user.avatar.glasses}.svg`}
                  style={{ position: "absolute" }}
                ></img>
              )
            )}
            {tryItem.hat ? (
              <img
                alt={`${tryItem.hat}`}
                src={`../Avatar/hat/${tryItem.hat}.svg`}
                style={{ position: "absolute" }}
              ></img>
            ) : (
              user.avatar.hat && (
                <img
                  alt={`${user.avatar.hat}`}
                  src={`../Avatar/hat/${user.avatar.hat}.svg`}
                  style={{ position: "absolute" }}
                ></img>
              )
            )}
            {tryItem.mouth ? (
              <img
                alt={`${tryItem.mouth}`}
                src={`../Avatar/mouth/${tryItem.mouth}.svg`}
                style={{ position: "absolute" }}
              ></img>
            ) : (
              user.avatar.mouth && (
                <img
                  alt={`${user.avatar.glasses}`}
                  src={`../Avatar/mouth/${user.avatar.mouth}.svg`}
                  style={{ position: "absolute" }}
                ></img>
              )
            )}
          </div>
          <div className={styles.btnContainer}>
            <button
              tabIndex="0"
              aria-label="Apply your items to character"
              data-analyticsidentifier={"saveAvatar"}
              disabled={noChange && !saveSuccess ? true : false}
              className={`${noChange && !saveSuccess ? styles.disabledBtn : ""} ${saveSuccess ? styles.success : ""} ${
                saveError ? styles.saveErr : ""
              } ${loadingResp ? styles.loading : ""} `}
              onClick={(e) => {
                updating();
                logButton(e);
              }}
            >
              {loadingResp ? (
                <img alt={`Loading Save`} src={"../Icons/loading.svg"} className={styles.loadingSave}></img>
              ) : (
                feedbackText()
              )}
            </button>
          </div>
          <div className={styles.showPinecones}>
            <img className={styles.pineconeIcon} alt="pinecone" src="../Icons/pinecone.svg" />
            <span className={styles.pineconeAmount}>{pinecones}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default UserCharacterPreview;

import styles from "./css/hamburger.module.scss";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

// list with routes is sent by App.js and the routes are located in content
const Hamburger = ({ linkList }) => {
  const [toggleHamburgerNav, setToggleHamburgerNav] = useState(false);
  const toggleHamNav = () => {
    toggleHamburgerNav ? setToggleHamburgerNav(false) : setToggleHamburgerNav(true);
    toggleHamburgerNav
      ? document.getElementById("root").classList.remove("bodyFixed")
      : document.getElementById("root").classList.add("bodyFixed");
  };

  return (
    <div className={styles.hamburgerMenu} onClick={toggleHamNav}>
      {toggleHamburgerNav && (
        <div
          className={styles.burgerNav}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <div className={styles.linkContainer}>
            {linkList.map((listItem, index) => {
              return (
                <div key={`burger${index}`}>
                  <Link to={listItem.path} onClick={toggleHamNav}>
                    {listItem.title}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className={styles.burger}>
        <div className={`${styles.burgerStroke} ${toggleHamburgerNav ? styles.firstBurgerStroke : ""}`}></div>
        <div className={`${styles.burgerStroke} ${toggleHamburgerNav ? styles.secondBurgerStroke : ""}`}></div>
        <div className={`${styles.burgerStroke} ${toggleHamburgerNav ? styles.thirdBurgerStroke : ""}`}></div>
      </div>
    </div>
  );
};

export default Hamburger;

import { PublicClientApplication, InteractionRequiredAuthError, LogLevel } from "@azure/msal-browser";

const configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
  },
  cache: {
    cacheLocation: "localStorage",
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) return;

        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            break;
        }
      },
      piiLoggingEnabled: false,
    },
  },
};

const msalInstance = new PublicClientApplication(configuration);

const acquireAccessToken = async () => {
  try {
    const accounts = msalInstance.getAllAccounts();
    const activeAccount = accounts.find((account) => account.tenantId === process.env.REACT_APP_AZURE_TENANT_ID);

    const request = {
      account: activeAccount,
      scopes: [process.env.REACT_APP_AZURE_API],
    };

    try {
      const res = await msalInstance.acquireTokenSilent(request);
      return res.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) msalInstance.acquireTokenRedirect(request);
      else throw error;
    }
  } catch (error) {
    console.error("unknown error thrown during acquireAccessToken: ", error);
  }
};

export { msalInstance, acquireAccessToken };

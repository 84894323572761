import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "./Context";
import { BrowserRouter as Router } from "react-router-dom";
import Loading from "./Views/Loading";
import Navbar from "./Components/Navbar";
import Hamburger from "./Components/Hamburger";
import RouteList from "./content/routeList.json";
import PathRoutes from "./PathsRoutes";
import "primeflex/primeflex.css";

const useWindowSize = () => {
  const [size, setSize] = useState([window.outerWidth, window.outerHeight]);

  useEffect(() => {
    const handleResize = () => {
      setSize([window.outerWidth, window.outerHeight]);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return size;
};

function App() {
  const { actions, state } = useContext(StoreContext);
  const [width] = useWindowSize();
  const [loading, setLoading] = useState(true);
  const [toggleHamburger, setToggleHamburger] = useState(false);
  // const [fixedBackground, setFixedBackground] = useState(false);
  //Calc on Menu width and the rest of the website. Looks weird works great.
  let menuWidth = 100;
  let marginOnRight = 10;
  let combine = menuWidth + marginOnRight;
  let takeFullWidthWithoutMenu = "calc( 100vw - " + combine + "px )";

  useEffect(() => {
    actions.fetchUserData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!Object.keys(state.userData).length) return;
    setLoading(false);
  }, [state.userData]);

  useEffect(() => {
    width < 960 ? setToggleHamburger(true) : setToggleHamburger(false);
  }, [width]);

  if (loading) {
    return (
      <div style={{ height: "100vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Loading />
      </div>
    );
  }

  return (
    <Router>
      <div style={{ marginTop: "100px", zIndex: "2", position: "relative" }}>
        <div className="grid" style={{ marginRight: marginOnRight + "px" }}>
          {!toggleHamburger && (
            <div
              className="col-12"
              style={{ padding: 0, position: "relative", width: menuWidth + "px", borderRadius: "25px" }}
            >
              <Navbar linkList={RouteList["RouteList"]} />
            </div>
          )}
          <div className="grid" style={{ flexDirection: "column", width: takeFullWidthWithoutMenu }}>
            <div className="App">
              <PathRoutes width={width} />
            </div>
          </div>
        </div>
      </div>
      {toggleHamburger && (
        <div
          style={{ position: "absolute", minHeight: "100vh", height: "100%", top: "-100px", right: "15px", zIndex: 5 }}
        >
          <Hamburger linkList={RouteList["RouteList"]} />
        </div>
      )}
      {
        <div
          style={{
            position: "fixed",
            height: "100%",
            width: "100%",
            overflowY: "hidden",
            left: "0",
            top: "0",
            zIndex: "1",
          }}
        >
          <div style={{ position: "absolute", bottom: "-5px", width: "100%", zIndex: "0" }}>
            <img
              alt="Background Trees"
              style={{ width: "100%", height: "100%" }}
              src={"../Background/background.svg"}
            />
          </div>
        </div>
      }
    </Router>
  );
}

export default App;

import styles from "./css/teamAvatarView.module.scss";
import TreeImage from "./TreeImage";

const TeamAvatarView = ({ team }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.flexWrapper} tabIndex="0" aria-label="This is a view of your team">
        {team ? (
          team.map((member, index) => {
            return (
              <div key={member.avatar.head + index} className={styles.userWrapper}>
                <div className={styles.treeWrapper}>
                  <TreeImage avatarView={true} userInformation={member} style={{ position: "absolute" }} />
                </div>
                <div>
                  <div className={styles.avatarWrapper}>
                    {member.avatar.body && (
                      <img
                        alt={`A ${member.avatar.body}'s body`}
                        src={`../Avatar/body/${member.avatar.body}.svg`}
                        style={{ position: "absolute" }}
                      ></img>
                    )}
                    {member.avatar.head && (
                      <img
                        alt={`A ${member.avatar.head}'s head`}
                        src={`../Avatar/head/${member.avatar.head}.svg`}
                        style={{ position: "absolute" }}
                      ></img>
                    )}
                    {member.avatar.glasses && (
                      <img
                        alt={`${member.avatar.glasses}`}
                        src={`../Avatar/glasses/${member.avatar.glasses}.svg`}
                        style={{ position: "absolute" }}
                      ></img>
                    )}
                    {member.avatar.hat && (
                      <img
                        alt={`${member.avatar.hat}`}
                        src={`../Avatar/hat/${member.avatar.hat}.svg`}
                        style={{ position: "absolute" }}
                      ></img>
                    )}
                    {member.avatar.mouth && (
                      <img
                        alt={`${member.avatar.mouth}`}
                        src={`../Avatar/mouth/${member.avatar.mouth}.svg`}
                        style={{ position: "absolute" }}
                      ></img>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className={styles.loadingText}>Loading Team</div>
        )}
      </div>
    </div>
  );
};
export default TeamAvatarView;

import React, { useEffect, useState } from "react";
import jsonPath from "../content/changelog.json";
import styles from "../Components/css/changelog.module.scss";

const Changelog = ({ title }) => {
  const [changelog, setChangelog] = useState("");

  useEffect(() => {
    (async () => {
      setChangelog(jsonPath);
    })();
  }, []);

  return (
    <>
      {changelog && (
        <div className={styles.wrapper}>
          <div className={styles.title}>
            {changelog.title && <h2 tabIndex="0">{changelog.title}</h2>}
            {changelog.info && <p tabIndex="0">{changelog.info}</p>}
          </div>
          <div className={styles.wrapperRealease}>
            {changelog.releases &&
              changelog.releases.map((release, index) => {
                return (
                  <div key={index * index}>
                    {release.title && (
                      <h3 tabIndex="0">
                        {release.date} {release.title} - {release.version}
                      </h3>
                    )}
                    <hr />
                    {release.info && <p tabIndex="0">{release.info}</p>}
                    {release.added && (
                      <>
                        <h3 tabIndex="0">Added</h3>
                        <ul tabIndex="0">
                          {release.added.map((added, indexNr) => {
                            return (
                              <li tabIndex="0" key={index * indexNr}>
                                {added}
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    )}
                    {release.removed && (
                      <>
                        <h3 tabIndex="0">Remove</h3>
                        <ul tabIndex="0">
                          {release.removed.map((removed, indexNr) => {
                            return (
                              <li tabIndex="0" key={index * indexNr}>
                                {removed}
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    )}
                    {release.fixed && (
                      <>
                        <h3 tabIndex="0">Fixed</h3>
                        <ul tabIndex="0">
                          {release.fixed.map((fixed, indexNr) => {
                            return (
                              <li tabIndex="0" key={index * indexNr}>
                                {fixed}
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};

export default Changelog;

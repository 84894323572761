import React from "react";
import styles from "../Components/css/projectsDropDownItem.module.scss";
import { logButton } from "../analytics";

const ProjectDropDownItem = ({ editList, project, setToggleSearchBox }) => {
  return (
    <button
      tabIndex="0"
      onKeyDownCapture={(e) => {
        if (e.key === "Escape") {
          setToggleSearchBox(false);
        }
      }}
      aria-label={`click to save ${project} to your project list `}
      data-analyticsidentifier={`add_${project}`}
      onClick={(e) => {
        editList(project, true);
        logButton(e);
      }}
      className={`${styles.searchItem} ${styles.disable_select}`}
    >
      {project}
      <div className={`s_item ${styles.itemOverlay}`} style={{ pointerEvents: "none" }}>
        <h5 className={styles.disable_select}>Add</h5>
      </div>
    </button>
  );
};

export default ProjectDropDownItem;

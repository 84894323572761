const initialState = {
  userData: {},
};

const types = {
  USER_DATA: "USER_DATA",
};

const reducer = (initialState, action) => {
  switch (action.type) {
    case types.USER_DATA:
      return {
        ...initialState,
        userData: action.payload,
      };
    default:
      throw new Error("Check reducers.js");
  }
};

export { initialState, types, reducer };

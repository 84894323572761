import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { StoreProvider } from "./Context/storeContext";
import axios from "axios";
//import reportWebVitals from './reportWebVitals';
import Authentication from "./Components/auth/Authentication";
import { acquireAccessToken } from "./services/msal";
import "../src/index.css";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(async (config) => {
  const accessToken = await acquireAccessToken();
  config.headers.authorization = "Bearer " + accessToken;
  return config;
});

ReactDOM.render(
  <React.StrictMode>
    <Authentication>
      <StoreProvider>
        <App />
      </StoreProvider>
    </Authentication>
  </React.StrictMode>,
  document.getElementById("root")
);

import styles from "./css/projectTimeLine.module.scss";
import { AiFillCar } from "react-icons/ai";

const EqualToProjectEmission = ({ project, userProjects }) => {
  let allCarKmEquivalent = 0;

  for (let i = 0; userProjects.length > i; i++) {
    if (project[userProjects[i]] === undefined) {
      continue;
    } else {
      //eslint-disable-next-line
      Object.values(project[userProjects[i]]["data"]).map((data) => {
        allCarKmEquivalent += data.carKmEquivalent;
      });
    }
  }
  const round = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  };

  return (
    <div className={styles.wrapperEqualCalc}>
      <h1 className={styles.header}>Monthly average CO2 emissions of selected GCP projects</h1>
      <div style={{ display: "flex", width: "100%", margin: " auto 0px" }}>
        <div className={styles.wrapperIconAndText}>
          <AiFillCar className={styles.icon} fill={"#d5d5d6"} />
          <div>Driving a car for {round(allCarKmEquivalent / 12, 1)} km</div>
        </div>
      </div>
    </div>
  );
};

export default EqualToProjectEmission;

import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import styles from "./css/dailyQuestion.module.scss";
import { StoreContext } from "../Context";
import Countdown from "react-countdown";
import { logExternalLink, logButton } from "../analytics";

const ChallengePopUp = ({ answerResponse, setAnswerResponse, question, time, toggle, triviaExist, setToggle }) => {
  const {
    state: { userData },
    actions,
  } = useContext(StoreContext);
  const [noTime, setNoTime] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState();

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (!completed) {
      return <span>{seconds}</span>;
    } else {
      setNoTime(true);
      return <span>{seconds}</span>;
    }
  };

  const checkAnswer = async (id, answer) => {
    setSelectedAnswer(answer);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/v1/player/trivia/answer-question`, {
        questionId: id,
        selectedAnswer: answer,
      })
      .then(function (response) {
        if (response.status === 201) {
          setAnswerResponse(response);
          actions.updateContext({
            ...userData,
            experience: response.data.player.experience,
            level: response.data.player.level,
            pinecones: response.data.player.pinecones,
            triviaActivities: [
              ...userData.triviaActivities,
              {
                questionId: id,
                created: new Date(),
              },
            ],
          });
        }
      })
      .catch(function (error) {
        if (error.response.status === 409) {
          setAnswerResponse(error.response);
          actions.updateContext({
            ...userData,
            triviaActivities: [
              ...userData.triviaActivities,
              {
                questionId: id,
                created: new Date(),
              },
            ],
          });
        }
      });
  };

  return (
    toggle && (
      <>
        {!triviaExist ? (
          <div className={styles.backgroundOverLay}>
            <div className={styles.completeChallengeWrapper}>
              {answerResponse === 0 && !noTime && (
                <>
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <Countdown date={time} renderer={renderer} />
                  </div>
                  <div className={styles.completeTitle}>Daily Question</div>
                  <div className={styles.completeChallengeName}>{question.question}</div>
                  <div className={styles.answerGrid}>
                    {question.answerOptions.map((answ) => {
                      return (
                        <button
                          data-analyticsidentifier={question.id + "-" + answ}
                          onClick={(e) => {
                            checkAnswer(question.id, answ);
                            logButton(e);
                          }}
                        >
                          {selectedAnswer !== undefined && selectedAnswer === answ ? (
                            <img alt={`Loading purchase`} src={"../Icons/loading.svg"} className={styles.loading}></img>
                          ) : (
                            answ
                          )}
                        </button>
                      );
                    })}
                  </div>
                </>
              )}
              {answerResponse.status === 201 && (
                <>
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <button className={styles.closeButton} onClick={() => setToggle(false)}>
                      X
                    </button>
                  </div>
                  <div className={styles.completeTitle}>Congratz you where Right!</div>
                  <div className={styles.completeChallengeName}>{question.question}</div>
                  <div style={{ display: "flex" }}>
                    <div className={styles.rightAnswer} style={{ backgroundColor: "#00e100" }}>
                      {selectedAnswer}
                    </div>
                    <div className={styles.arrow}>&#8594;</div>
                    <div className={styles.rightAnswer}>{answerResponse.data.correctAnswer}</div>
                  </div>
                  <div className={styles.readMore}>
                    <a
                      href={question.additionalInformationUrl}
                      onClick={(e) => {
                        logExternalLink(e);
                      }}
                    >
                      Learn More here
                    </a>
                  </div>
                </>
              )}
              {answerResponse.status === 409 && (
                <>
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <button className={styles.closeButton} onClick={() => setToggle(false)}>
                      X
                    </button>
                  </div>
                  <div className={styles.completeTitle}>Sorry you where Wrong!</div>
                  <div className={styles.completeChallengeName}>{question.question}</div>
                  <div className={styles.message}>Better luck tomorrow!</div>
                  <div style={{ display: "flex" }}>
                    <div className={styles.wrongAnswer}>{selectedAnswer}</div>
                    <div className={styles.arrow}>&#8594;</div>
                    <div className={styles.rightAnswer}>{answerResponse.data.correctAnswer}</div>
                  </div>
                  <div className={styles.readMore}>
                    <a
                      href={question.additionalInformationUrl}
                      onClick={(e) => {
                        logExternalLink(e);
                      }}
                    >
                      Learn More here
                    </a>
                  </div>
                </>
              )}
              {noTime && (
                <>
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <button className={styles.closeButton} onClick={() => setToggle(false)}>
                      X
                    </button>
                  </div>
                  <div className={styles.completeTitle}>Time ran out...</div>
                  <div className={styles.completeChallengeName}>{question.question}</div>
                  <div className={styles.readMore}>
                    <a
                      href={question.additionalInformationUrl}
                      onClick={(e) => {
                        logExternalLink(e);
                      }}
                    >
                      Learn More here
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.backgroundOverLay}>
            <div className={styles.completeChallengeWrapper}>
              <div style={{ display: "flex", justifyContent: "right" }}>
                <button className={styles.closeButton} onClick={() => setToggle(false)}>
                  X
                </button>
              </div>
              <div className={styles.completeTitle}>Comeback tomorrow!</div>
              <div className={styles.message}>You already answered today</div>
              <div className={styles.readMore}>
                <a
                  href={question.additionalInformationUrl}
                  onClick={(e) => {
                    logExternalLink(e);
                  }}
                >
                  Learn More here
                </a>
              </div>
            </div>
          </div>
        )}
      </>
    )
  );
};

export default ChallengePopUp;

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineChartProjects = ({ project, everyDatePointer }) => {
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        position: "top",
        labels: {
          color: "white",
        },
      },
      title: {
        display: true,
        text: "CO2 emissions by GCP project",
        color: "white",
        font: {
          size: 25,
          family: "Oregano",
        },
      },
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        ticks: {
          color: "white",
          font: {
            family: "Oregano",
          },
        },
      },
    },
  };

  const data = {
    labels: everyDatePointer,
    datasets: Object.keys(project).map((data, index) => {
      let borderColor, backgroundColor;
      const indexColor = [
        "#00a0e9",
        "#94ff6f",
        "#b88df1",
        "#f07cd8",
        "#ff70af",
        "#ff747e",
        "#ff894a",
        "#ffa600",
        "#00e5c9",
        "#7399f6",
      ];
      borderColor = indexColor[index];
      backgroundColor = indexColor[index];

      return {
        label: data,
        data: Object.keys(project[data]["data"]).map((x) => {
          return project[data]["data"][x].carKmEquivalent;
        }),
        borderColor: borderColor,
        backgroundColor: backgroundColor,
      };
    }),
  };

  return (
    <div style={{ height: "100%", position: "relative", marginBottom: "1%", padding: "1%" }}>
      <Line options={options} data={data} />
    </div>
  );
};

export default LineChartProjects;

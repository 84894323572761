import React, { useEffect } from "react";
import { logExternalLink } from "../analytics";
import styles from "../Components/css/sustainabilityListItem.module.scss";

const SustainabilityListItem = ({ listItem }) => {
  return (
    <a
      tabIndex="0"
      className={styles.linkCSS}
      href={listItem.srcURL}
      onClick={(e) => {
        logExternalLink(e);
      }}
    >
      <div className={styles.listItemContainer} style={{ pointerEvents: "none" }}>
        <h3 className={styles.titleSmallDot}>{listItem.title}</h3>
        <p className={styles.textList}>{listItem.description}</p>
      </div>
    </a>
  );
};

export default SustainabilityListItem;
